import {
    challenge_reducers,
    lesson_reducers as lr,
    poll_reducers,
    practice_reducers,
    question_reducers,
    response_reducers,
    response_point_reducers,
    stream_reducers,
    toasts,
    user_reducers,
    loading
} from "mindsets-js-sdk";
import {list_reload} from "mindsets-js-sdk/src/react/layout/ListPage/reducers";

import lesson_reducers from "./lesson_reducers";

export default Object.assign(
    {},
    {toasts, loading, list_reload},
    challenge_reducers,
    Object.assign({}, lr, lesson_reducers),
    poll_reducers,
    practice_reducers,
    question_reducers,
    response_reducers,
    response_point_reducers,
    stream_reducers,
    toasts,
    user_reducers
);
