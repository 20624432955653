import React from 'react';
import PropTypes from "prop-types";

const Fields = function ({on_auth, email, setEmail, password, setPassword}) {
    if (on_auth && !!email && !!password) {
        return null;
    }

    return (
        <>
            <div className="form-group">
                <label htmlFor="email" className="sr-only">Email address</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="password" className="sr-only">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                />
            </div>
        </>
    );
}

Fields.propTypes = {
    on_auth: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired
}

export default Fields;
