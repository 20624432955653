import React from 'react';
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.schedules);
    const current = useSelector(state => state.schedules_current);
    const limit = useSelector(state => state.schedules_limit);
    let number_students_to_grade = 0;
    let number_students_graded = 0;
    let number_responses_to_grade = 0;
    let number_responses_graded = 0;
    items.map(item => {
        if (item.number_students_to_grade > 0) {
            number_students_to_grade += item.number_students_to_grade;
        }
        if (item.number_students_graded > 0) {
            number_students_graded += item.number_students_graded;
        }
        if (item.number_responses_to_grade > 0) {
            number_responses_to_grade += item.number_responses_to_grade;
        }
        if (item.number_responses_graded > 0) {
            number_responses_graded += item.number_responses_graded;
        }
    });

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">Class Code</th>
                    <th scope="col">Assessment Status</th>
                    <th scope="col">Class Created on</th>
                    <th scope="col">Scheduled on</th>
                    <th scope="col">Standard</th>
                    <th scope="col"># students graded/requested</th>
                    <th scope="col"># responses graded/requested</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return (
                        <Row
                            key={item.id}
                            item={item}
                            row_number={getRowNumber(index, current, limit)}
                        />
                    );
                })}
                <tr>
                    <td colSpan={6}><strong>Total</strong></td>
                    <td className='text-center'>
                        <strong>
                            <span
                                className={number_students_graded !== number_students_to_grade ? 'text-danger' : ''}
                            >{number_students_graded}</span>
                            <span>/</span>
                            <span>{number_students_to_grade}</span>
                        </strong>
                    </td>
                    <td className='text-center'>
                        <strong>
                            <span
                                className={number_responses_graded !== number_responses_to_grade ? 'text-danger' : ''}
                            >{number_responses_graded}</span>
                            <span>/</span>
                            <span>{number_responses_to_grade}</span>
                        </strong>
                    </td>
                    <td>&nbsp;</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}
