import React from 'react';
import {CustomToast, Loader, Footer} from "mindsets-js-sdk";

export default function ({children}) {
    return (
        <>
            <CustomToast/>
            <div className="container">
                {children}
            </div>
            <Footer/>
            <Loader/>
        </>
    )
}
