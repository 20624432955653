import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {
    CustomToast,
    Header,
    Footer,
    Loader,
    LoadMeAction,
    ErrorMessageAction,
    authUtils,
    accessConstants
} from "mindsets-js-sdk";

import WhatIsNew from "./head/WhatIsNew";
import UserMenu from "./head/menu/UserMenu";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    useEffect(() => {
        if (MindsetsJsSDK().Auth.isAuthenticated()) {
            dispatch(LoadMeAction());
            dispatch(LoadMeAction()).then(user => {
                if (authUtils.iCant(user, [accessConstants.PERMISSION_CAN_LOGIN_TO_RUBRIC])) {
                    dispatch(ErrorMessageAction('You do not have enough permissions to access GradeBook. Please, contact us.'));
                    MindsetsJsSDK().Auth.logout();
                    window.location.href = '/login';
                }
            });
        } else {
            if (location.pathname !== '/login') {
                window.location.href = '/login';
            }
        }
    }, []);
    useEffect(() => {
        if (me) {
            const channel = pusher.subscribe('user_' + me.id);
            channel.bind('user.updated', () => dispatch(LoadMeAction()));
            channel.bind('signed_out_' + process.env.PUBLIC_APP_SOURCE, function () {
                MindsetsJsSDK().Auth.logout();
                window.location.href = "/";
            });
            return () => channel.unsubscribe();
        }
    }, [me]);

    if (me) {
        return (
            <>
                <CustomToast/>
                <Header main_menu={<WhatIsNew/>} user_menu={<UserMenu/>}/>
                <div className={"container full"}>
                    <Outlet/>
                </div>
                <Footer/>
                <Loader/>
            </>
        );
    }

    return (
        <>
            <div>Loading...</div>
        </>
    );
};
