import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

class Spreadsheet extends Component {
    renderTableHeader() {
        if (this.props.question.spreadsheet.headers.length > 0) {
            return (
                <thead>
                {this.props.question.spreadsheet.headers.map((row, i) => {
                    if (!Array.isArray(row)) {
                        row = Object.values(row);
                    }

                    return (
                        <tr key={i}>
                            {row.map((cell, n) => {
                                const style = (typeof cell === 'object') ? cell.style : undefined
                                const text = (typeof cell === 'object') ? cell.title : cell
                                return (
                                    <th key={n} style={style}>
                                        {text ? <HTMLContent
                                            html_str={contentUtils.processStringContent(text, {convert_spelling: false})}/> : null}
                                    </th>
                                )
                            })}
                        </tr>
                    )
                })}
                </thead>
            )
        }
    }

    renderImage(cell) {
        if (!!cell && cell.image) {
            return (
                <div>
                    <img src={cell.image} alt=''/>
                </div>
            )
        }
    }

    renderText(cell) {
        if (!cell) {
            return <span>&nbsp;</span>;
        }
        if (_.isString(cell)) {
            return contentUtils.processStringContent(cell, {convert_spelling: false});
        }
        if (cell.editable !== undefined && !cell.editable) {
            return (
                <HTMLContent html_str={contentUtils.processStringContent(cell.title, {convert_spelling: false})}/>
            );
        }

        return contentUtils.processStringContent(_.isString(cell) ? cell : cell.title, {convert_spelling: false});
    }

    renderTableRows() {
        const spreadsheet_body_data = _.merge(
            [],
            this.props.question.spreadsheet.data,
            this.props.response.data.answer
        );

        return spreadsheet_body_data.map((row, row_key) => {
            if (!Array.isArray(row)) {
                row = Object.values(row);
            }

            return (
                <tr key={row_key}>
                    {row.map((cell, col_key) => {
                        return (
                            <td key={col_key}>
                                <div>
                                    {this.renderImage(cell)}
                                    {this.renderText(cell)}
                                </div>
                            </td>
                        );
                    })}
                </tr>
            );
        })
    }

    render() {
        if (this.props.response) {
            return (
                <table>
                    {this.renderTableHeader()}

                    <tbody>
                    {this.renderTableRows()}
                    </tbody>
                </table>
            )
        }

        return null;
    }
}

Spreadsheet.propTypes = {
    question: PropTypes.shape({
        spreadsheet: PropTypes.shape({
            headers: PropTypes.arrayOf(PropTypes.object).isRequired,
            data: PropTypes.arrayOf(PropTypes.object).isRequired,
        }).isRequired
    }).isRequired,
    response: PropTypes.shape({
        data: PropTypes.shape({
            answer: PropTypes.array
        }).isRequired
    })
};

export default Spreadsheet;
