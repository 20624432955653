import React from 'react';
import {useSelector} from "react-redux";
import {CustomDropdown, Cookie} from "mindsets-js-sdk";

import {whatisnew_data} from '../../whatisnew_data';

import './what_is_new.scss';

const WHAT_IS_NEW_COOKE_NAME = 'mindsets_what_is_new_last_seen';

export default () => {
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }
    const lastSeen = Cookie.getCookie(WHAT_IS_NEW_COOKE_NAME + '_' + me.id);
    const news = whatisnew_data.filter(win => (lastSeen ? win.date >= lastSeen : true));
    if (news.length <= 0) {
        return null;
    }

    let items = {
        0: <h3>What's new</h3>
    };
    news.map((whatisnew, index) => {
        items[index + 1] = (
            <div>
                <div><strong>{whatisnew.title}</strong></div>
                <div>{whatisnew.description}</div>
            </div>
        );
    });

    return (
        <div className='what-is-new'>
            <CustomDropdown
                trigger={(
                    <i
                        className="fas fa-info fa-2x"
                        onClick={() => Cookie.setCookie(WHAT_IS_NEW_COOKE_NAME + '_' + me.id, new Date().toISOString().substring(0, 10))}
                    />
                )}
                no_wrap={true}
                items={items}
            />
        </div>
    );
}
