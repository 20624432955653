import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {LoadingAction, ErrorMessageAction, UpdateScheduleAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const schedule = useSelector(state => state.lesson_scoring_schedule);
    if (!!schedule && schedule.status === 'started') {
        return (
            <button
                className="button green"
                onClick={() => {
                    if (confirm('Are you sure you have finished reviewing before submitting?')) {
                        dispatch(UpdateScheduleAction(schedule.id, {status: 'finished'}))
                            .then(() => navigate('/'))
                            .catch(error => {
                                dispatch(LoadingAction(false));
                                dispatch(ErrorMessageAction(error));
                                console.error(error);
                            });
                    }
                }}
            >Finish assessment
            </button>
        )
    }

    return null;
}
