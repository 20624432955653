import MindsetsJsSDK, {LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

export const LOADED_LESSONS_PRACTICES = '[lessons] Loaded practices';

export const LoadPracticesAction = () => dispatch => {
    return Promise.resolve()
        .then(() => dispatch(LoadingAction(true, 'Loading practices')))
        .then(() => MindsetsJsSDK().API.get('practices', {order_by: 'name'}))
        .then(response => dispatch(LoadedPracticesAction(response.items)))
        .then(() => dispatch(LoadingAction(false)))
        .catch(error => {
            dispatch(LoadingAction(false));
            dispatch(ErrorMessageAction(error));
            console.error(error);
        });
};
export const LoadedPracticesAction = payload => ({
    type: LOADED_LESSONS_PRACTICES,
    payload
});
