import React from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils, mathUtils} from "mindsets-js-sdk";

import Spreadsheet from '../../../common/Spreadsheet';
import Vote from '../../../common/Vote';

const Content = function ({question, response}) {
    const answer_type = question.answer_type ? question.answer_type : question.type;
    if (!response) {
        return (
            <div className='badge bg-warning'>NR</div>
        );
    }
    if ('answer.expression' === answer_type) {
        return (
            <HTMLContent
                html_str={contentUtils.processStringContent(mathUtils.convertLatexToKatex(response.data.answer), {convert_spelling: false})}/>
        );
    } else if ('answer.expression_multi' === answer_type) {
        return (
            <div>{response.data.answer.filter(answer => !!answer).map((answer, index) => {
                return (
                    <div key={index}>
                        <HTMLContent
                            html_str={contentUtils.processStringContent(mathUtils.convertLatexToKatex(answer), {convert_spelling: false})}
                        />
                    </div>
                );
            })}</div>
        );
    } else if ('answer.choice_single' === answer_type) {
        return contentUtils.processStringContent(response.data.answer + ') ' + question.options[response.data.answer], {convert_spelling: false});
    } else if (['answer.dnd', 'answer.number_only', 'answer.fraction'].includes(answer_type)) {
        if (response.data.answer.text) {
            return contentUtils.processStringContent(response.data.answer.text, {convert_spelling: false});
        } else {
            return (
                <div className='badge bg-warning'>NR</div>
            );
        }
    } else if ('answer.range' === answer_type) {
        return response.data.answer.min + ' - ' + response.data.answer.max;
    } else if ('answer.graphing' === answer_type) {
        if (response.data.answer.answer_option === 'tool') {
            return (
                <a href={`/response/${response.id}`} target='_blank'>Open graph in new tab</a>
            );
        } else {
            return 'Student used paper';
        }
    } else if ('answer.selector' === answer_type || 'answer.matching' === answer_type) {
        if (response.data.answer.text) {
            return (
                <HTMLContent html_str={response.data.answer.text.replace(/\n/g, '<br/>')}/>
            );
        } else {
            return (
                <div className='badge bg-warning'>NR</div>
            );
        }
    } else if (answer_type.startsWith('answer.') || ['text', 'slider'].includes(answer_type)) {
        return contentUtils.processStringContent(response.data.answer, {convert_spelling: false});
    } else if ('range' === answer_type) {
        return contentUtils.processStringContent(
            'min: ' + response.data.answer.min + ', max: ' + response.data.answer.max,
            {convert_spelling: false}
        );
    } else if ('spreadsheet' === answer_type) {
        return <Spreadsheet question={question} response={response}/>;
    } else if (answer_type === 'poll.inline') {
        return <Vote question={question} response={response}/>;
    }

    return null;
}

Content.propTypes = {
    question: PropTypes.shape({
        answer_type: PropTypes.string,
        type: PropTypes.string,
        options: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ])
    }).isRequired,
    response: PropTypes.shape({
        id: PropTypes.number.isRequired,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    })
};

export default Content;
