import React from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import MindsetsJsSDK from "mindsets-js-sdk";

import "./forbidden.scss";

export default function () {
    const location = useLocation();
    const navigate = useNavigate();
    const renderLink = function () {
        if (!MindsetsJsSDK().Auth.isAuthenticated()) {
            return <Link to={"/login?redirect_url=" + encodeURIComponent(location.pathname)}>to the login
                page.</Link>;
        } else {
            return <Link to="/">back home</Link>;
        }
    }

    return (
        <div className="section center forbidden">
            <div className="forbidden-title">YOU SHALL NOT PASS</div>
            <img className="forbidden-image" src={process.env.PUBLIC_S3_ASSETS_URL + '/application/403.png'}
                 alt="Page forbidden"/>
            <div className="forbidden-sub-title">
                <div className="row">
                    <div className="col l6 m6 s12 offset-l3 offset-m3">
                        We are sorry, but you do not have access to this page or resource.
                    </div>
                </div>
            </div>
            <div className="forbidden-back">
                <span>Return to the</span>&nbsp;
                <a href={"!#"} onClick={event => {
                    event.preventDefault();
                    navigate(-1);
                }}>previous page</a>&nbsp;
                <span>or go</span>&nbsp;
                {renderLink()}
                &nbsp;<span>or</span>&nbsp;
                <a href={"!#"} onClick={event => {
                    event.preventDefault();
                    MindsetsJsSDK().Auth.logout();
                    navigate('/login');
                }}>Logout</a>
            </div>
            <div className="not-found-contact-us">
                <div className="flex-row flex-vh-center flex-box-2-5">
                    <i className="far fa-envelope"/>
                    <a href="https://learn.mindsets.com/hc/en-us/requests/new" target="_blank">Contact us</a>
                </div>
            </div>
        </div>
    );
};
