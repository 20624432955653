import {LOADED_LESSONS_PRACTICES} from "../actions/lesson_actions";

const lessons_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_LESSONS_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    lessons_practices
}
