import React from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import MindsetsJsSDK, {CustomDropdown} from "mindsets-js-sdk";

export default () => {
    const navigate = useNavigate();
    const me = useSelector(state => state.me);
    if (!me) {
        return null;
    }

    return (
        <CustomDropdown
            trigger={<div className={"mindsets-icon " + me.avatar}/>}
            selected={window.location.pathname}
            no_wrap={true}
            items={{
                back: <a href="https://go.mindsets.com" className='dropdown-item'>Back to Mindsets</a>,
                help: <a href="https://learn.mindsets.com" className='dropdown-item' target="_blank">Help</a>,
                divider: <hr/>,
                logout: <a href={"!#"} className="dropdown-item" onClick={event => {
                    event.preventDefault();
                    MindsetsJsSDK().API.post('auth/logout').then(() => {
                        MindsetsJsSDK().Auth.logout();
                        navigate('/login');
                    });
                }}>Logout</a>
            }}
            use_arrow={false}
        />
    );
}
