import MindsetsJsSDK, {
    LoadingAction,
    ErrorMessageAction,
    InfoMessageAction,
    AuthCodeIssuedAction,
} from "mindsets-js-sdk";

export const IssueAuthCodeAction = data => dispatch => {
    dispatch(LoadingAction(true));

    return MindsetsJsSDK().API
        .post('auth/gradebook/login', data)
        .then(() => {
            dispatch(AuthCodeIssuedAction(true));
            dispatch(InfoMessageAction('An email with the authentication code has been sent to your email address. Paste it into the field bellow.', true));
        })
        .catch(error => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        })
        .then(() => dispatch(LoadingAction(false)))
};

export const LogInEmailAction = data => dispatch => {
    dispatch(LoadingAction(true, 'Logging you in'));

    return MindsetsJsSDK().API
        .post(`auth/gradebook/login`, data)
        .then(() => window.location.href = '/')
        .catch(error => {
            console.error(error);
            dispatch(ErrorMessageAction(error));
        })
        .then(() => dispatch(LoadingAction(false)));
};
