import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {LoadMeAction, LoadResponseAction} from "mindsets-js-sdk";

import BasicLayout from "../../layout/BasicLayout";
import Graphing from "./Graphing";

import './response.scss';

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const response = useSelector(state => state.response);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(LoadMeAction());
        dispatch(LoadResponseAction(params.response_id)).then(() => setLoading(false));
    }, []);

    function renderStudent() {
        if (loading) {
            return null;
        }

        if (!!me && me.persona !== 'student' && !!response) {
            const student_name = response.user.fname;
            return (
                <div>
                    <span>{`Student: `}</span>
                    <strong>{`${student_name}`}</strong>
                </div>
            );
        } else {
            const student_id = response.user.id;
            return (
                <div>
                    <span>{`Student: `}</span>
                    <strong>{`${student_id}`}</strong>
                </div>
            );
        }
    }

    function renderClose() {
        if (!me) {
            return null;
        }

        return (
            <button className="button btn-on-white" onClick={() => window.close()}>Close</button>
        )
    }

    function renderGraphing() {
        if (loading) {
            return null;
        }

        if (!!response && response.data.answer.answer_option === 'tool') {
            return (
                <Graphing answer={response}/>
            );
        } else {
            return (
                <div>
                    {`There is an error loading the student graphing answer, please try to refresh this page.`}
                    <br/>
                    {`If error still persists, please contact support.`}
                </div>
            )
        }
    }

    return (
        <BasicLayout>
            <div id="answer-graphing">
                <div className="d-flex justify-content-between align-items-center">
                    <img
                        className="mindsets-logo-guest"
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/logos/mindsets_logo_blue.svg`}
                        alt="Mindsets Learning Inc."
                    />

                    <div>
                        {renderClose()}
                    </div>
                </div>

                <div className="section">
                    <div>
                        {renderStudent()}
                    </div>
                    <div>
                        {`Note: Any changes you make to this student's graph will not be saved.`}
                    </div>
                </div>

                <div className="section">
                    {renderGraphing()}
                </div>
            </div>
        </BasicLayout>
    );
}
