import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import ActionButton from "./ActionButton";
import QAButton from "./QAButton";
import {datesConstants} from "mindsets-js-sdk";

const Row = function ({item, row_number}) {
    const practices = useSelector(state => state.lessons_practices);

    return (
        <tr>
            <td>{row_number}</td>
            <td>
                <div><strong>{item.lesson.code.toUpperCase()}</strong></div>
                <div className='no-wrap'>{item.lesson.challenge_version.name}(v.{item.lesson.challenge_version.version})</div>
                <div className='no-wrap'>{item.lesson.mode === 'teacher' ? 'Teacher-paced mode' : 'Student paced mode'}</div>
            </td>
            <td>{_.upperFirst(item.status)}</td>
            <td className='no-wrap'>{new Date(item.lesson.created_at * 1000).toLocaleString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td className='no-wrap'>{new Date(item.created_at * 1000).toLocaleString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td>{practices.find(practice => practice.id === item.practice_id).name}</td>
            <td className='text-center'>
                <span
                    className={item.number_students_graded !== item.number_students_to_grade ? 'text-danger' : ''}
                >{item.number_students_graded >= 0 ? item.number_students_graded : 'na'}</span>
                <span>/</span>
                <span>{item.number_students_to_grade >= 0 ? item.number_students_to_grade : 'na'}</span>
            </td>
            <td className='text-center'>
                <span
                    className={item.number_responses_graded !== item.number_responses_to_grade ? 'text-danger' : ''}
                >{item.number_responses_graded >= 0 ? item.number_responses_graded : 'na'}</span>
                <span>/</span>
                <span>{item.number_responses_to_grade >= 0 ? item.number_responses_to_grade : 'na'}</span>
            </td>
            <td>
                <div className='d-flex flex-wrap flex-gap-10'>
                    <ActionButton schedule={item}/>
                    <QAButton schedule={item}/>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string.isRequired,
        user_id: PropTypes.number,
        lesson_id: PropTypes.number,
        practice_id: PropTypes.number.isRequired,
        grading_provider_id: PropTypes.number,
        created_at: PropTypes.number,
        lesson: PropTypes.shape({
            id: PropTypes.number.isRequired,
            code: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            challenge_version: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        number_students: PropTypes.number,
        number_students_to_grade: PropTypes.number,
        number_students_graded: PropTypes.number,
        number_responses: PropTypes.number,
        number_responses_to_grade: PropTypes.number,
        number_responses_graded: PropTypes.number,
    }).isRequired,
    row_number: PropTypes.number.isRequired,
};

export default Row;
