import React from 'react';
import {BasicLayout} from "mindsets-js-sdk";

import FullLayout from "./layout/FullLayout";
import Login from "./pages/Login";
import Schedules from "./pages/Schedules";
import Scoring from "./pages/Scoring";
import QA from "./pages/QA";
import NoMatch from "./pages/NoMatch";
import Forbidden from "./pages/Forbidden";
import Error from "./pages/Error";
import RubricPreview from "./pages/RubricPreview";
import Response from "./pages/Response";

export default [
    {
        path: '/',
        element: <FullLayout/>,
        errorElement: <Error/>,
        children: [
            {
                path: '/',
                element: <Schedules/>
            },
            {
                path: '/schedule/:schedule_id',
                element: <Scoring/>
            },
            {
                path: '/qa/:schedule_id',
                element: <QA/>
            },
            {
                path: '/challenges/:challenge_code/practice/:practice_code/preview',
                element: <RubricPreview/>
            }
        ]
    },
    {
        path: '/response/:response_id',
        element: <Response/>,
        errorElement: <Error/>
    },
    {
        path: '/login',
        element: <BasicLayout/>,
        errorElement: <Error/>,
        children: [
            {
                path: '/login',
                element: <Login/>
            }
        ]
    },
    {
        path: '/403',
        element: <Forbidden/>
    },
    {
        path: '/404',
        element: <NoMatch/>
    },
    {
        path: '*',
        element: <NoMatch/>
    }
];
