import React from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

const Vote = function ({question, response}) {
    if (response) {
        const poll_option_index = question.poll.poll_options.findIndex(poll_option => poll_option.id === response.data.answer);
        if (poll_option_index !== -1) {
            return (
                <div>
                    <div>Option {poll_option_index + 1}</div>
                    <HTMLContent
                        html_str={contentUtils.processStringContent(question.poll.poll_options[poll_option_index].option, {convert_spelling: false})}/>
                </div>
            );
        }
    }

    return null;
}

Vote.propTypes = {
    question: PropTypes.shape({
        poll: PropTypes.shape({
            poll_options: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.string.isRequired,
                description: PropTypes.string,
                option: PropTypes.string.isRequired,
            })).isRequired
        }).isRequired
    }).isRequired,
    response: PropTypes.shape({
        data: PropTypes.shape({
            answer: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
        }).isRequired
    })
};

export default Vote;
