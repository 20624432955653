import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MindsetsJsSDK, {ErrorMessageAction, AuthCodeIssuedAction} from "mindsets-js-sdk";

import {LogInEmailAction, IssueAuthCodeAction} from "../../actions/login_actions";
import AuthCodeField from "./components/AuthCodeField";
import Fields from "./components/Fields";

import './login.scss';

export default function () {
    const dispatch = useDispatch();
    const on_auth = useSelector(state => state.onAuth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [auth_code, setAuthCode] = useState('');
    useEffect(() => {
        if (MindsetsJsSDK().Auth.isAuthenticated()) {
            window.location.href = '/';
        }
    }, []);
    const validate = function () {
        return email.length > 0 && password.length > 0;
    }
    const validateAuth = function () {
        return validate() && auth_code.length > 0;
    }

    return (
        <div className="d-flex justify-content-center">
            <form className="login-form text-center" onSubmit={event => {
                event.preventDefault();
                if (on_auth) {
                    if (validateAuth()) {
                        dispatch(LogInEmailAction({email, password, auth_code}));
                    } else {
                        dispatch(ErrorMessageAction('Please type in your Authentication Code'));
                    }
                } else {
                    if (validate()) {
                        dispatch(IssueAuthCodeAction({email, password}));
                    } else {
                        dispatch(ErrorMessageAction('Please type in your email address and password'));
                    }
                }
            }}>
                <img
                    src={process.env.PUBLIC_S3_ASSETS_URL + "/logos/mindsets_logo_color_bulb.svg"}
                    alt="Mindsets Logo"
                    className="mb-4"/>
                <h1>Please sign in</h1>
                <Fields
                    on_auth={on_auth}
                    email={email}
                    password={password}
                    setEmail={setEmail}
                    setPassword={setPassword}
                />
                <AuthCodeField on_auth={on_auth} auth_code={auth_code} setAuthCode={setAuthCode}/>
                <button
                    type="submit"
                    className="button big inverted d-block w-100"
                    disabled={!email || !password}
                >Log in
                </button>
                {on_auth ? (
                    <a href={'#'} onClick={event => {
                        event.preventDefault();
                        dispatch(AuthCodeIssuedAction(false))
                    }}>I Don't have a code</a>
                ) : (
                    <a href={'#'} onClick={event => {
                        event.preventDefault();
                        dispatch(AuthCodeIssuedAction(true))
                    }}>I've got the code</a>
                )}
            </form>
        </div>
    );
}

