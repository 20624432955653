import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Graphing extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'answer-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100)
    }

    componentDidMount() {
        this.ggbInit();
    }

    graphingAnswerConfig(uniq_id) {
        const width = document.getElementById(`component-${uniq_id}`).offsetWidth
        const height = Math.round(width / 1.5)
        return {
            appName: "graphing",
            id: "graphing_instance",
            width: width,
            height: height,
            enableRightClick: false,
            enableLabelDrags: false,
            showResetIcon: true,
            showZoomButtons: true,
            borderColor: null,
            rounding: "2",
            showToolBar: true,
            showAlgebraInput: true,
            customToolBar: '73 0 1 2 | 40 41 42 39 | 62 25 17 26 27 61 14 58 | 6',
            preventFocus: true
        }
    }

    ggbInit() {
        const ggb_config = {
            ...this.graphingAnswerConfig(this.uniq_id),
            appletOnLoad: (graphing_instance) => {
                this.graphing_instance = graphing_instance
            }
        }

        const base64 = _.get(this.props.answer, 'data.answer.base64')
        if (base64) {
            ggb_config.ggbBase64 = base64
        }

        this.ggbApp = new GGBApplet(ggb_config, true)
        this.ggbApp.inject(this.uniq_id)
    }

    render() {
        return (
            <div id={`component-${this.uniq_id}`}>
                <div id={this.uniq_id}></div>
            </div>
        )
    }
}

Graphing.propTypes = {
    answer: PropTypes.object
}

export default Graphing
