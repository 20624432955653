export const whatisnew_data = [
  {
    "date": "2022-03-24",
    "title": "Correct/Incorrect",
    "description": "'Answer Result' field has been extended to cover slider range, number line, DND and Expression question types."
  },
  {
    "date": "2022-05-30",
    "title": "Grading suggestions",
    "description": "Grading suggestions have been temporarily hidden."
  },
  {
    "date": "2022-06-02",
    "title": "Number of students",
    "description": "The number of students was removed from the list of classes requested for grading to avoid confusion."
  },
  {
    "date": "2022-06-10",
    "title": "More filters",
    "description": "More filters have been added to the list of lessons: District, Owner, Teaching mode and Requested standards."
  },
  {
    "date": "2022-09-29",
    "title": "Sorting/Ordering",
    "description": "A new sorting/ordering feature has been added to the top right corner. Classes are now ordered by the date of creation in descending order by default(Latest first)."
  },
  {
    "date": "2022-12-20",
    "title": "Navigation",
    "description": "Navigation between questions and students has been improved. It is scrollable!"
  },
  {
    "date": "2023-06-15",
    "title": "New answer type",
    "description": "Added support for a new answer type 'Number Only'"
  },
  {
    "date": "2023-06-15",
    "title": "New answer type",
    "description": "Added support for a new answer type 'Fraction'"
  },
  {
    "date": "2023-06-15",
    "title": "Graphing answer page",
    "description": "The Graphing answer page opens in GradeBook now instead of redirecting to the Go app"
  },
  {
    "date": "2023-06-15",
    "title": "Home page rebuild",
    "description": "The Home page has been rebuild to provide better experience. The numbers of graded/requested students and responses have been added for convenience"
  },
  {
    "date": "2023-06-15",
    "title": "Auto-grading",
    "description": "Our system now automatically grades some responses. They are greyed out. The scores are still editable if necessarily."
  }
]
