import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    SchoolDropdown,
    ChallengeDropdown,
    UserDropdown,
    DatePicker,
    DistrictDropdown,
    ListPage,
    accessConstants,
    filterConstants,
    LoadSchedulesAction,
    catchQueryFilters
} from "mindsets-js-sdk";

import {LoadPracticesAction} from "../../actions/lesson_actions";
import Table from "./components/Table";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const practices = useSelector(state => state.lessons_practices);

    useEffect(() => {
        if (!practices.length) {
            dispatch(LoadPracticesAction());
        }
    }, []);
    if (!practices.length) {
        return null;
    }
    if (!me) {
        return null;
    }

    return (
        <>
            <h1>Classes requested for assessment</h1>
            <ListPage
                action={options => LoadSchedulesAction(Object.assign({}, {
                    'filters[grading_provider_user_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + me.id,
                    'selectors[0]': 'lesson_challenge_version',
                    'selectors[2]': 'number_students_to_grade',
                    'selectors[3]': 'number_students_graded',
                    'selectors[5]': 'number_responses_to_grade',
                    'selectors[6]': 'number_responses_graded',
                }, options))}
                filter_columns={[
                    {
                        key: 'school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: (setFilterValue, index) => {
                            const filter = catchQueryFilters()[index];

                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        setFilterValue(school.id);

                                        return school.name;
                                    }}
                                    district_id={filter ? filter.value : undefined}
                                    id='lessons-school'
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'district_id',
                        name: 'District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <DistrictDropdown onSelect={district => {
                                    setFilterValue(district.id);

                                    return district.name;
                                }} id='lessons-district'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'challenge_code',
                        name: 'Challenge',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown onSelect={challenge_version => {
                                    setFilterValue(challenge_version.challenge_code.code);

                                    return challenge_version.name;
                                }} id='lessons-challenge'/>
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'lesson_user_id',
                        name: 'Class creator',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: (setFilterValue, index) => {
                            const filter = catchQueryFilters()[index];

                            return (
                                <UserDropdown
                                    onSelect={user => {
                                        setFilterValue(user.id);

                                        return user.fname + ' ' + user.lname;
                                    }}
                                    school_id={filter ? filter.value : undefined}
                                    id='lessons-user'
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'lesson_created_at',
                        name: 'Class Created on',
                        expressions: [filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE],
                        component: (setFilterValue, index) => {
                            const filter = catchQueryFilters()[index];

                            return (
                                <DatePicker
                                    date={Number.isInteger(filter.value) ? filter.value : null}
                                    onChange={setFilterValue}/>
                            );
                        },
                    },
                    {
                        key: 'created_at',
                        name: 'Scheduled on',
                        expressions: [filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE],
                        component: (setFilterValue, index) => {
                            const filter = catchQueryFilters()[index];

                            return (
                                <DatePicker
                                    date={Number.isInteger(filter.value) ? filter.value : null}
                                    onChange={setFilterValue}/>
                            );
                        },
                    },
                    {
                        key: 'status',
                        name: 'Assessment Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [
                            {
                                label: 'Requested',
                                value: 'requested'
                            },
                            {
                                label: 'Started',
                                value: 'started'
                            },
                            {
                                label: 'Finished',
                                value: 'finished'
                            },
                            {
                                label: 'QA Passed',
                                value: 'QA passed'
                            }
                        ]
                    },
                    {
                        key: 'lesson_mode',
                        name: 'Teaching mode',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [
                            {
                                label: 'Student-paced',
                                value: 'student'
                            },
                            {
                                label: 'Teacher-paced',
                                value: 'teacher'
                            }
                        ]
                    },
                    {
                        key: 'practice_id',
                        name: 'Requested standard',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: practices.map(practice => ({
                            label: practice.name,
                            value: practice.id
                        }))
                    }
                ]}
                sort_columns={[
                    {
                        key: 'created_at',
                        name: 'Scheduled On'
                    }
                ]}
                entity={'schedules'}
                permissions={[accessConstants.PERMISSION_CAN_GRADE_OWN_STUDENTS, accessConstants.PERMISSION_CAN_GRADE_ALL_STUDENTS]}
                custom_export={<></>}
                quick_search_placeholder='Code or Name'
                default_order_direction='desc'
            >
                <Table/>
            </ListPage>
        </>
    );
}
